/* All input css start here  */
.form-control:disabled,
.disabled-select-box-with-icon .react-select-dealerlist,
fieldset.disabled-form-field input {
    background-color: #F3F4F6 !important;
}

.default-fieldset {
    margin: 0px;
    padding: 0px;
    border: 1px solid #D1D5DB;
    border-radius: 5px;
    /* height: 100%; */
    /* transition: all ease-in-out 0.5s; */
}

fieldset:focus-within {
    border: 2px solid #243b7f !important;
    background-color: #fff !important;
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.05);
}

fieldset.default-checkbox:focus-within,
fieldset.default-radio-btn:focus-within {
    border: 0px !important;
    box-shadow: none;
}

fieldset:hover {
    background-color: rgba(17, 25, 39, 0.04);
}

.default-fieldset input,
.default-fieldset textarea {
    background-color: transparent !important;
}

.input-error-message,
.invalid-feedback {
    font-size: 12px;
    color: #A10F0F;
    font-weight: 500;
    text-shadow: none;
    letter-spacing: .3px;
}

.input-error-message {
    line-height: 18px;
    display: inline-block;
    margin: 4px 0 0 0;
}

.default-fieldset.InputAutoHeight {
    height: auto !important;
}

.default-fieldset-label {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding: 1px 4px;
    width: auto;
    margin: 0px 0 0 12px;
}

.default-fieldset .input-label-wrap {
    display: flex;
    align-items: center;
}

.default-fieldset .input-field-wrap {
    width: 18px;
    height: 18px;
    display: inline-flex;
    position: relative;
}

.default-fieldset input {
    border: 0px !important;
    box-shadow: none !important;
    border-radius: 5px;
    padding: 7px 13px 9px;
    font-family: 'Inter', sans-serif;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #111111 !important;
    line-height: initial !important;
    width: 100%;
    border-radius: 5px;
    outline: none !important;
}

.default-fieldset .react-select-dealerlist input {
    width: auto;
}

.default-fieldset .select-box-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 33px;
}

.default-fieldset .react-select-dealerlist {
    flex: 1;
    max-width: 100%;
}

.default-fieldset .react-select-dealerlist [class*='-control'],
.default-fieldset .react-select-dealerlist [class*='-control'] * {
    border: 0 !important;
    box-shadow: none !important;
    min-height: auto;
    max-height: 33px;
    font-size: 14px;
}

.default-fieldset .react-select-dealerlist [class*="-indicatorSeparator"] {
    display: none;
}

.default-fieldset .react-select-dealerlist [class*="-indicator"] {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    right: 0px;
    z-index: 1;
    top: 0;
}

.default-fieldset .select-box-with-icon .dropdown-icon {
    position: absolute;
    top: 7px;
    right: 10px;
}

.default-fieldset .select-box-with-icon {
    height: 33px;
}

.default-fieldset .react-select-dealerlist [class*='-control'],
.default-fieldset .react-select-dealerlist [class*='-control'] * {
    max-height: 33px !important;
}

.default-fieldset .react-select-dealerlist input {
    height: auto !important;
}

.default-fieldset input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #757A82 !important;
    opacity: 1;
}

.default-fieldset input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #757A82 !important;
}

.default-fieldset input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #757A82 !important;
}

.default-fieldset .react-select-dealerlist input {
    max-height: 23px;
    padding: 5px 0px !important;
}

.form-row {
    align-items: flex-start;
}

/* -------------- Custom Check Box & Radio Button --------------- */

.default-fieldset.default-checkbox,
.default-fieldset.default-radio-btn {
    border: transparent;
    background: transparent !important;
}

.default-fieldset.default-checkbox .default-fieldset-label,
.default-fieldset.default-radio-btn .default-fieldset-label {
    font-weight: 400;
    padding: 0;
    font-size: 14px;
}

.default-fieldset input.form-checkbox,
.default-fieldset input.form-radio-btn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.default-check-box-img,
.default-radio-btn-img,
.default-disable-check-box-img,
.default-disable-radio-box-img {
    width: 18px;
    height: 18px;
    display: inline-flex;
}

.default-disable-check-box-img,
.default-fieldset input.form-checkbox:disabled {
    cursor: auto;
}

.default-disable-check-box-img {
    background-image: url('../../../../../assets/images/Icons/Checkbox-Disable.svg');
    background-size: cover;
}

.default-check-box-img {
    background-image: url('../../../../../assets/images/Icons/CheckBox.svg');
    background-size: cover;
}

.default-fieldset input.form-checkbox:checked~.default-check-box-img {
    background-image: url('../../../../../assets/images/Icons/CheckBox-Active.svg');
}

.default-fieldset input.form-checkbox:checked~.default-check-box-img.default-checked-disable-check-box-img {
    background-image: url('../../../../../assets/images/Icons/disabled-gray-checkbox.svg');
    background-size: cover;
}

.default-radio-btn-img {
    background-image: url('../../../../../assets/images/Icons/RadioBtn.svg');
    /* background-size: 18px 18px; */
    background-size: cover;
    background-position: center center;
}

.default-fieldset input.form-radio-btn:checked~.default-radio-btn-img {
    background-image: url('../../../../../assets/images/Icons/RadioBtn-Active.svg');
    background-size: cover;
    background-position: center center;
}

.default-disable-radio-box-img {
    background-image: url('../../../../../assets/images/Icons/disable-radio-btn.svg');
    background-size: cover;
}

.label-icon-image {
    display: inline-flex;
    margin: 0 5px 0 10px;
}

.assignassentypes-popup .label-icon-image {
    width: 24px;
}

.label-icon-image~.default-fieldset-label {
    margin: 0;
}

.radioCheckboxLabel {
    margin-left: 6px;
}

/* -------------- Custom Check Box Ends here --------------- */

/* -------------- Date Picker Starts here --------------- */

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker-wrapper .react-datepicker__close-icon {
    right: 27px;
}

.react-datepicker-wrapper .react-datepicker__close-icon::after,
.react-datepicker__month-container .react-datepicker__day--keyboard-selected,
.react-datepicker__month-container .react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-container .react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__month-container .react-datepicker__year-text--keyboard-selected {
    background-color: #243B7F;
}

.default-date-picker {
    min-width: 145px;
}

.default-date-picker .calendar-icon {
    position: absolute;
    top: 47%;
    right: 10px;
    transform: translateY(-50%);
}

.default-date-picker fieldset {
    position: relative;
    border-color: #D1D5DB;
}

.default-date-picker .react-datepicker {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: 0;
}

.default-date-picker .react-datepicker__header {
    background-color: white !important;
    border: 0;
    padding: 0;
    margin: 0 0 12px 0
}

.default-date-picker .react-datepicker__current-month {
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
}

.default-date-picker .react-datepicker__header__dropdown {
    margin-bottom: 10px;
}

.default-date-picker .react-datepicker__month {
    margin: 0;
}

.default-date-picker .react-datepicker__year-read-view--down-arrow {
    top: 4px;
}

.default-date-picker .react-datepicker__year-read-view--selected-year {
    margin-left: -15px;
}

.default-date-picker .react-datepicker__month-container {
    min-width: 320px;
    padding: 13px 16px 16px;
}

.default-date-picker .react-datepicker__day-names,
.default-date-picker .react-datepicker__month .react-datepicker__week {
    display: flex;
    justify-content: space-between;
}

.default-date-picker .react-datepicker__month .react-datepicker__week>div {
    color: #111111;
    font-weight: 400;
    font-size: 14px;
    line-height: initial;
    letter-spacing: 0.15px;
    margin: 0;
}

.default-date-picker .react-datepicker__day-names>div {
    color: rgba(55, 65, 81, 0.48);
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    margin: 0;
    width: 36px;
}

.default-date-picker .react-datepicker__month .react-datepicker__week>div {
    width: 36px;
    height: 36px;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px
}

.default-date-picker .react-datepicker__day--selected,
.default-date-picker .react-datepicker__day--keyboard-selected {
    background-color: #243B7F !important;
    color: white !important;
}

.advancedSearchFilters .react-select-dealerlist div[class$="-menu"],
.react-select-dealerlist div[class$="-menu"],
.shared-react-select div[class$="-menu"] {
    z-index: 10;
}


.react-select-dealerlist div[class$="-option"] {
    background-color: transparent !important;
    color: #111 !important;
    font-size: 14px;
}

.advancedSearchFilters .react-select-dealerlist div[class$="-menu"]>div {
    max-height: 175px;
}

.default-date-picker input {
    color: #111111;
    border-radius: 5px;
    background-color: #fff;
}

.default-date-picker .react-datepicker__triangle {
    display: none;
}

.default-date-picker .react-datepicker__navigation--previous {
    border-color: #111 !important;
    border-width: 0 0 2px 2px;
    transform: rotate(45deg);
    top: 20px;
    left: 20px;
}

.default-date-picker .react-datepicker__navigation--next {
    border-color: #111 !important;
    border-width: 0 0 2px 2px;
    transform: rotate(-135deg);
    top: 20px;
    right: 20px;
}

.default-date-picker .react-datepicker__month .react-datepicker__week>div.react-datepicker__day--outside-month {
    visibility: hidden;
}

.default-date-picker .react-datepicker-popper {
    z-index: 9;
}

/* .table-filters .table-filters .filtes-calenderIcon {
    min-height: 33px;
}

.table-filters .table-filters .default-fieldset {
    min-height: 33px;
} */

.default-table .table-filters input {
    border-radius: 4px;
    background-color: #fff;
}

.default-table .table-filters .react-datepicker-wrapper input {
    background-color: #fff !important;
}

.default-table .table-filters .react-datepicker-wrapper input:hover {
    background-color: rgba(17, 25, 39, 0.04) !important;
}

.default-table .table-filters .react-datepicker-wrapper {
    /* background-color: #fff; */
    border-radius: 4px;
}

.default-date-picker input {
    background: url('../../../../../assets/images/Icons/sm-calendar-icon.svg') no-repeat right;
    background-size: 18px;
    background-position: right 10px bottom 6px;
    min-width: 105px;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc !important;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    background-color: #243B7F !important;
    color: #fff !important;
}

/* -------------- Date Picker Ends here --------------- */

/* Text Area starts here */
.default-fieldset textarea.form-control {
    border: 0;
    box-shadow: none !important;
    resize: none;
    font-size: 14px;
    color: #111;
    background-image: none !important;
}

/* Text Area ends here */


/*  File upload group css start here */
.file-upload-input-group {
    position: relative;
}

.file-upload-input-group label {
    position: absolute;
    left: 10px;
    top: 8px;
    color: #9E9E9E;
    font-size: 14px;
}

.file-upload-input-group {
    display: flex;
    width: auto;
    min-width: 281px;
    height: 40px;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 10px;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid #D1D5DB;
    border-radius: 3px;
    overflow: hidden;
}

.file-upload-input-group input {
    opacity: 0;
}

.file-upload-input-group input::placeholder {
    font-size: 14px;
}

.file-upload-input-group button {
    border: 0px;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    height: 40px;
    border-left: 1px solid #D1D5DB !important;
    outline: none !important;
    box-shadow: none !important;
}

.file-upload-input-group button::before {
    border-radius: 0 !important;
}

.file-upload-input-group button:disabled {
    color: #9E9E9E !important;
    /* background-color: transparent !important; */
}

.file-upload-input-group button {
    color: #fff !important;
    background-color: #243B7F !important;
}

/*  File upload group css end here */
.input-group-styles fieldset {
    border-right: 0;
    border-radius: 4px 0 0 4px;
    flex: 1;
}

.input-group-styles input {
    border-radius: 5px 0 0 5px;
}

.input-group-styles .input-group-text {
    background-color: #F3F4F6;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 500;
}

.input-group-styles input[disabled] {
    border-radius: 4px 0 0 4px;
}

.input-group-styles .invalid-feedback {
    order: 1;
}

.group-label .input-group-text {
    margin-top: 7px;
}

.custom-switch .custom-control-label::before {
    width: 22px;
    box-shadow: none !important;
    outline: none !important;
    background-color: #fff !important;
    border-color: #9E9E9E !important;
    cursor: pointer;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    left: auto;
    right: 15px;
    transform: none;
}

input {
    background-image: none !important;
}

.react-select-dealerlist input,
.partnerACHCreditTransaction table .form-check-input.position-static {
    position: relative !important;
}

.react-select-dealerlist input[checked]::after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    background-image: url(../../../../../assets/images/Icons/CheckBox-Active.svg) !important;
    background-size: cover !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 2px !important;
}

.react-select-dealerlist input::after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    background-image: url(../../../../../assets/images/Icons/CheckBox.svg) !important;
    background-size: cover !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 2px !important;
}

.react-select-dealerlist div[class$="-menu"] input:checked::after,
.partnerACHCreditTransaction table td:first-child input:checked::after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    background-image: url(../../../../../assets/images/Icons/CheckBox-Active.svg) !important;
    background-size: cover !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 2px !important;
}

.react-select-dealerlist div[class$="-menu"] input::after,
.partnerACHCreditTransaction table td:first-child input::after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    background-image: url(../../../../../assets/images/Icons/CheckBox.svg) !important;
    background-size: cover !important;
    left: 0 !important;
    top: 00% !important;
    border-radius: 2px !important;
    cursor: pointer;
}

.react-select-dealerlist div[class$="-multiValue"] div:nth-child(2):hover {
    background-color: #A10F0F !important;
    color: #fff;
    cursor: pointer;
}

.cust-multi-select-box .select-box-with-icon,
.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'],
.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'] * {
    max-height: 33px !important;
    height: auto;
    background-color: transparent !important;
}

.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control']~div {
    z-index: 5;
}

.multi-select-box-height.cust-multi-select-box .select-box-with-icon,
.multi-select-box-height.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'],
.multi-select-box-height.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'] * {
    max-height: unset !important;
    height: auto;
}

.cust-multi-select-box.default-fieldset .react-select-dealerlist [class$='-multiValue'] {
    background-color: #e6e6e6 !important;
    max-width: 95%;
}


/* Multi Select Close Icon Start */

.cust-multi-select-box .react-select-dealerlist [class*="-indicator"]:first-child {
    right: 30px;
    opacity: 0;
    top: 0;
}

.cust-multi-select-box.default-fieldset .react-select-dealerlist [class$="-control"]>div:nth-child(2) {
    width: 58px;
}

.cust-multi-select-box .close-icon-clearable {
    position: absolute;
    right: 43px;
    top: 4px;
}

.cust-multi-select-box .close-icon-clearable::after {
    content: '';
    height: 20px;
    width: 1px;
    display: block;
    background-color: #9E9E9E;
    position: absolute;
    right: -9px;
    top: 3px;
}

.cust-multi-select-box .react-select-dealerlist [class*="-option"]:hover {
    background-color: #F3F4F6 !important;
}

.select-white-bg {
    background-color: white;
}

.multi-selected-option {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cust-multi-select-box.dropdown-height div[class$="-menu"]>div {
    max-height: 135px;
}

.default-table .table-filters .react-table-datalist-selector [class$="-control"] {
    border: 1px solid #e0e0e0 !important;
}

.default-table .table-filters .react-table-datalist-selector:focus-within {
    outline: 2px solid #243B7F !important;
    border-radius: 4px;
}

/* Multi Select Close Icon End */

/* All input css end here  */


@media (max-width: 1599px) {

    .default-fieldset.default-checkbox .default-fieldset-label,
    .default-fieldset.default-radio-btn .default-fieldset-label {
        font-size: 12px;
    }

    /* .login-page-wrapper .default-fieldset {
        margin: 0px 0 13px 0;
    } */


    .table-filters .default-fieldset input {
        /* font-size: 10px !important; */
    }

    .default-table .default-fieldset .default-fieldset-label {
        font-size: 12px;
    }

    .default-fieldset.default-checkbox,
    .default-fieldset.default-radio-btn {
        margin: 0px 0px 6px 0px;
    }

    .default-fieldset.default-checkbox .input-field-wrap,
    .default-fieldset.default-radio-btn .input-field-wrap,
    .default-check-box-img,
    .default-radio-btn-img,
    .default-disable-check-box-img,
    .default-disable-radio-box-img {
        width: 14px;
        height: 14px;
    }

    .default-fieldset input,
    .default-fieldset .react-select-dealerlist [class*='-control'],
    .default-fieldset .react-select-dealerlist [class*='-control'] *,
    .react-select-dealerlist div[class$="-option"],
    .default-fieldset textarea.form-control {
        font-size: 12px !important;
    }

    .default-date-picker {
        min-width: 135px;
    }

    .default-date-picker .react-datepicker__current-month {
        font-size: 14px;
    }

    .default-date-picker .react-datepicker__month-container {
        min-width: 230px;
        padding: 6px 8px 8px;
    }

    .default-date-picker .react-datepicker__header {
        margin: 0 0 8px 0;
    }

    .default-date-picker .react-datepicker__header__dropdown {
        margin-bottom: 5px;
    }

    .default-date-picker .react-datepicker__month .react-datepicker__week>div {
        width: 20px;
        height: 20px;
        font-size: 12px;
    }

    .default-date-picker .react-datepicker__day-names>div {
        width: auto;
        text-align: center;
        min-width: 15px;
    }

}