.toast {
    max-width: 450px;
    width: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    border-radius: 4.8px;
    z-index: 1051;
}

.toast-header {
    height: 62px;
    line-height: 62px;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    padding: 10px 18px;
    border: 0px;
}

.toast-body {
    padding: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #111;
}

.toast-body::first-letter {
    text-transform: capitalize;
}

.close {
    opacity: 1;
    font-size: 0px !important;
    width: 15px;
    height: 15px;
    background-image: url('../../../assets/images/Popup-Icons/PopupIcons_CloseWhitePNG.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0px !important;
}

.error-toaster .toast-header {
    background-color: #A10F0F;
}

.success-toaster .toast-header {
    background-color: #0E8074;
}

.warning-toaster .toast-header {
    background-color: #A67905;
}

@media (max-width: 767px) {
    .toast {
        width: 97%;
        right: 5px !important;
        left: auto !important;
        margin: 0 auto !important;
    }
}