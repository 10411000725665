/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@700&family=Roboto:wght@100;300;400;500;700;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Allan:wght@400;700&family=Work+Sans:wght@100;200;300;400;500;600;700&Mulish:wght@300;400;500;600;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; /* Enable hardware acceleration for smoother scrolling on iOS */
  scroll-behavior: smooth; /* Enable smooth scrolling behavior */
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  transition: background-color 0.3s;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Force the scrollbar to be always visible */
/* body::-webkit-scrollbar-thumb:vertical {
  min-height: 20px;
} */

/* Force the scrollbar to be always visible when hovering over it */
/* body::-webkit-scrollbar-thumb:hover:vertical {
  min-height: 20px;
} */

code {
  font-family: 'Roboto', sans-serif;
}
