/* Color pallet for our lever application here */

/* Background colors here */

.page-body-bg-color,
.page-wrapper,
.top-list-bg,
.bg-F3F4F6 {
    background-color: #F3F4F6 !important;
}

.darkblue-bg-color {
    background-color: #111827;
}

.primary-button-bg-color,
.primary-blue-bg {
    background-color: #243B7F;
}

.modal-backdrop-bg-color {
    background: rgba(0, 0, 0, 0.54);
}

.default-fieldset-label {
    color: #111111;
}

.white-text {
    color: #fff;
}

.white-text-important {
    color: #fff !important;
}

.default-fieldset {
    border-color: #D1D5DB !important;
}

.default-fieldset input {
    color: #111111;
}

.blue-link-text {
    color: #243B7F !important;
}

.default-table small,
.secondary-text-color small,
.default-table input.secondary-text-color,
.secondary-text-color,
.expanded-row-icon,
.column-sort-icon {
    color: #757A82;
}

.default-table p.secondary-text-color,
p.secondary-text-color,
.notes-in-table-col span {
    color: #757A82 !important;
}

.default-table p,
.primary-text-color,
.primary-text-color,
.black-link,
.time-in-balck-color {
    color: #111111 !important;
}

.primary-blue-color {
    color: #243B7F !important;
}

.disabled-text {
    color: #3741517A;
}

.nav-item.active .tabDataCount {
    background: #243B7F;
}

.icon-color,
.color-9E9E9E {
    color: #9E9E9E;
}

.border-9e9e9e {
    border-color: #9e9e9e;
}

.progress-bar {
    background-color: #243B7F;
}

.other-status-pill,
.default-status-pill {
    background-color: #9E9E9E;
}

.default-status-color {
    color: #9E9E9E !important;
}

.green-status-color {
    color: #0E8074 !important;
}

.green-status-pill,
.bg-0E8074 {
    background-color: #0E8074 !important;
}

.blue-status-pill,
.bg-3832A0 {
    background-color: #3832A0;
}

.red-status-color {
    color: #A10F0F !important;
}

.red-status-pill,
.bg-A10F0F {
    background-color: #A10F0F !important;
}

.brownish-status-color {
    color: #A67905;
}

.brownish-status-pill,
.bg-A67905 {
    background-color: #A67905 !important;
}

.purple-status-pill,
.bg-7C067E {
    background-color: #7C067E;
}

.table-header-bg-color,
.greyish-bg-color {
    background-color: #eff1f6 !important;
}

.high-level-risk-bg-color {
    background-color: #A10F0F;
}

.medium-level-risk-bg-color {
    background-color: #A67905;
}

.low-level-risk-bg-color {
    background-color: #0E8074;
}

.default-level-risk-bg-color {
    background-color: #9E9E9E;
}

.bg-transparent {
    background-color: transparent;
}

.color-52575C {
    color: #52575C;
}

.progress-bar,
.bg-243B7F {
    background-color: #243B7F;
}