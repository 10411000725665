@import url('../Shared/css/colors.css');


.login-from {
    max-width: 600px;
    margin: auto;
    padding: 32px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    min-height: 600px;
    display: flex;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.login-from .site-logo {
    width: 62px;
    display: block;
    margin: auto;
    margin-bottom: 21px;
}

.site-title {
    font-size: 24px;
    font-weight: 700;
    color: #111111;
    line-height: 33px;
    margin-bottom: 16px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.full-width {
    width: 100% !important;
}

.login-from h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0.4px;
    color: #111111;
    margin-bottom: 8px;
}

.login-from h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: 20px;
    color: #757A82;
}

.login-from label {
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 0px;
}

.login-from input[type='email'],
.login-from input[type='password'] {
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.15px;
    outline: none !important;
}

.login-error-feedback {
    font-size: 80%;
    color: #dc3545;
    margin-top: 10px;
    margin-bottom: 5px;
}

.btn-before-login {
    box-shadow: none !important;
    width: 100%;
    padding: 0px;
    height: 42px;
    line-height: 42px;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
}

.login-other-action-link {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #243B7F;
}

.login-other-action-block {
    text-align: right;
    margin-bottom: 15px;
}

.login-page-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
    /* background: url('../../assets/images/Login-background-image.svg') #E5E5E5 top center no-repeat; */
    /* background-color: #E3E3E3; */
}

.login-page-wrapper .container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.login-page-wrapper .default-btn {
    padding: 11px 18px;
}

.signup-btn {
    border: 1px solid #243B7F;
    padding: 10px 18px;
    text-decoration: none !important;
    border-radius: 8px;
    display: block;
    margin: auto;
    color: #243B7F !important;
    font-size: 14px;
    font-weight: 600;
}

input[type='password']:focus,
input[type='email']:focus {
    outline: none !important;
    box-shadow: none !important;
}

input:invalid {
    box-shadow: 0px 1px 0px #A10F0F;
}

input:valid {
    box-shadow: none;
}

@media (max-width: 1599px) {
    .login-from {
        max-width: 500px;
        min-height: auto;
    }

    .site-title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .login-from h2 {
        font-size: 20px;
    }

    .login-from h5 {
        font-size: 12px;
    }

    .signup-btn,
    .login-page-wrapper .default-btn {
        font-size: 13px;
        padding: 8px 18px;
    }

    .login-other-action-link {
        font-size: 13px;
    }

}

@media (max-width: 767px) {
    .login-from {
        max-width: 100%;
        padding: 20px;
        min-height: 100%;
    }

    .login-page-wrapper .container {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .loginWelcomText {
        font-size: 19px !important;
        margin-bottom: 0 !important;
    }
}