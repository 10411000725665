.app-loader {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    z-index: 99;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
}

.app-loader img {
    max-width: 70px !important;
}

.app-loader.fixed {
    position: fixed;
}

.app-loader.absolute {
    position: absolute;
}

img.loader-gif-icon {
    width: 52px;
}

@media(max-width: 992px) {
    img.loader-gif-icon {
        width: 47px;
    }
}

@media(max-width: 767px) {
    img.loader-gif-icon {
        width: 43px;
    }
}